import { useEffect, useRef } from "react";

function useScrollCue() {
  const scrollCueRef = useRef(null);

  useEffect(() => {
    import("scrollcue")
      .then((scrollCueModule) => {
        const scrollCue = scrollCueModule.default || scrollCueModule;

        if (scrollCue && typeof scrollCue.init === "function") {
          scrollCueRef.current = scrollCue;

          setTimeout(() => {
            try {
              scrollCue.init({
                duration: 600,
                interval: -100,
                percentage: 0.1, // Lower percentage to trigger animations earlier
                docSlider: false,
                pageChangeReset: false,
              });

              // Force update after initialization
              setTimeout(() => {
                if (
                  scrollCueRef.current &&
                  typeof scrollCueRef.current.update === "function"
                ) {
                  scrollCueRef.current.update();
                }
              }, 100);
            } catch (error) {
              console.error("ScrollCue initialization error:", error);
            }
          }, 0);
        }
      })
      .catch((error) => {
        console.error("Failed to load ScrollCue:", error);
      });

    return () => {
      if (
        scrollCueRef.current &&
        typeof scrollCueRef.current.destroy === "function"
      ) {
        try {
          scrollCueRef.current.destroy();
        } catch (error) {
          console.error("ScrollCue cleanup error:", error);
        }
      }
    };
  }, []);
}

export default useScrollCue;
