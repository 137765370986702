import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";

const HeroCountdown = () => {
  return (
    <section id="hero">
      <video loop muted autoPlay>
        <source
          src="https://d11n7da8rpqbjy.cloudfront.net/aicc/31013299_1724252189422JNJ.mp4"
          type="video/mp4"
        />
      </video>
      <div class="herobg">
        <div class="tljngl cbounce">
          <img src="/assets/images/jnglpage/tl-jngl.png" alt="Jingle" />
        </div>
        <div class="trjngl cbounce2">
          <img src="/assets/images/jnglpage/tr-jngl.png" alt="Jingle" />
        </div>
        <div class="brjngl">
          <img src="/assets/images/jnglpage/br-jngl.png" alt="Jingle" />
        </div>
      </div>
      <div class="container">
        <div class="row g-5 py-5">
          <div class="col-12 ptmain">
            <h3 className="jnglfont" data-cue="slideInDown">
              Jungle Upgrade
            </h3>
            <div className="countdown-wrapper interfont">
              <FlipClockCountdown
                className="flip-clock"
                to={1729483200000}
                labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroCountdown;
