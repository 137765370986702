import React from "react";
import { useState, useEffect } from "react";
/*
ToDO's
Add links/routes

*/
const NavMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenuOpen]);

  return (
    <>
      {isMenuOpen && (
        <div
          class={isMenuOpen ? "overlay open" : "overlay"}
          id="overlay"
          data-lenis-prevent
        >
          <video loop muted playsInline autoPlay>
            <source
              src="https://d11n7da8rpqbjy.cloudfront.net/aicc/31013299_1724252189422JNJ.mp4"
              type="video/mp4"
            />
          </video>
          <div class="overlay-header d-flex justify-content-between">
            <div class="ologo">
              <img src="/assets/images/navmenu/jngl-coin-logo.png" />
            </div>
            <div class="oclose">
              <button
                class={"navclose"}
                onClick={() => {
                  setIsMenuOpen(false);
                }}
              ></button>
            </div>
          </div>
          <div class="wrap_menu">
            <nav class="overlay-menu">
              <ul>
                <li>
                  <a href="/coming-soon">Staking Lab</a>
                </li>
                <li>
                  <a href="/coming-soon">Marketplace</a>
                </li>
                <li>
                  <a href="/coming-soon">Inventory</a>
                </li>
                <li>
                  <a href="https://docs.jnglcoin.com/">Whitepaper</a>
                </li>
                <li>
                  <a href="/coming-soon">SK Portal</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="overlay-btns">
            <div class="d-flex justify-content-between">
              <div class="btnsocial">
                <ul>
                  <li>
                    <a href="https://x.com/jnglcoin">
                      <i class="bi bi-twitter-x"></i> X(Twitter)
                    </a>
                  </li>
                  <li>
                    <a href="https://discord.gg/supremekong">
                      <i class="bi bi-discord"></i> Discord
                    </a>
                  </li>
                  <li>
                    <a href="https://t.co/07J2doTdJp">
                      <i class="bi bi-telegram"></i> Telegram
                    </a>
                  </li>
                </ul>
              </div>
              <div class="copyright">
                Copyright &copy; 2024 JUNGLE LABZ. ALL RIGHTS RESERVED
              </div>
              <div class="plinks">
                <ul>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Terms of Use</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="wrapstars">
            <div class="stars"></div>
            <div class="stars3"></div>
          </div>
        </div>
      )}
      <header class="menuBar">
        <nav class="side-bar d-lg-flex">
          <div class="side-bar__top">
            <a href="./" class="side-bar__mid">
              <img alt="logo" src="/assets/images/navmenu/jngl-coin-logo.png" />
            </a>
          </div>
          <div
            id="nav-burger"
            class={isMenuOpen ? "" : "open"}
            onClick={() => {
              setIsMenuOpen(true);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </nav>
      </header>
    </>
  );
};

export default NavMenu;
