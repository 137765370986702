const JunglenomicsCard = () => {
  return (
    <section id="jungleconomics">
      <video loop muted autoPlay>
        <source
          src="https://d11n7da8rpqbjy.cloudfront.net/aicc/31013294_1723204626561launch_cropped.mp4"
          type="video/mp4"
        />
      </video>
      <img
        src="/assets/images/jnglpage/JungleEconomics.png"
        data-cue="slideInUp"
        class="img-fluid"
      />
      <img src="/assets/images/jnglpage/jeconomics-m.png" class="d-none" />
    </section>
  );
};

export default JunglenomicsCard;
