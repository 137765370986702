const SubHeroCard = () => {
  return (
    <section id="subhero" class="secpad">
      <div class="subh-stone1 cbounce">
        <img src="/assets/images/jnglpage/sub-stone1.png" />
      </div>
      <div class="subh-stone2 cbounce2">
        <img src="/assets/images/jnglpage/sub-stone2.png" />
      </div>
      <div class="container subhero-inner-container">
        <div class="row">
          <div class="col-lg-12 m-auto">
            <div class="subhbox subhero-inner-container" data-cue="slideInLeft">
              <div class="shadow out"></div>
              <div class="d-flex justify-content-center">
                <img
                  src="/assets/images/jnglpage/tglow.png"
                  alt="JNGL Logo"
                  class="sub-jnglicon"
                />
                <div class="subhtext">
                  <p class="title" data-cue="slideInLeft">
                    Mission Statement
                  </p>
                  <p class="desc" data-cue="slideInLeft">
                    Our mission is to build a vibrant legacy community that for
                    generations will instill the morals and commitment we
                    believe a brand in crypto should have. Regardless of age,
                    location or financial background, if you’re in the jungle,
                    there are pathways to grow one self and network with
                    likeminded leaders, creatives, and builders. This is our
                    vision for Jungle Labz.
                  </p>
                </div>
                <a
                  href="https://docs.jnglcoin.com/"
                  target="_blank"
                  class="btn btn-outline-white"
                  data-cue="slideInLeft"
                >
                  Whitepaper <i class="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubHeroCard;
