const JnglRoadmapCard = () => {
  return (
    <section id="roadmap" class="secpad">
      <div class="rmjnglicn rmtl cbounce">
        <img src="/assets/images/jnglpage/rm-tl.png" alt="jngl icon" />
      </div>{" "}
      <div class="rmjnglicn rmtr cbounce2">
        <img src="/assets/images/jnglpage/rm-tr.png" alt="jngl icon" />
      </div>{" "}
      <div class="rmjnglicn rmbr cbouncebc">
        <img src="/assets/images/jnglpage/rm-br.png" alt="jngl icon" />
      </div>
      <div class="stars"></div>
      <div class="stars3"></div>
      <div class="container">
        <div class="row">
          <div class="wrap-roadmaphead text-center">
            <img
              src="/assets/images/jnglpage/roadmaplogo.png"
              data-cue="slideInUp"
              alt="roadmap"
            />
            <h1 data-cue="slideInDown">Roadmap</h1>
            <a
              href="https://app.uniswap.org/explore/tokens/ethereum/0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155"
              class="btn btn-secondary mt-lg-4"
              data-cue="slideInLeft"
            >
              Buy Here
            </a>{" "}
            <a
              href="https://www.supremekong.com/jngl/staking"
              class="btn btn-outline-white ms-lg-4 mt-lg-4 stakejngl"
              data-cue="slideInRight"
            >
              Stake $JNGL <i class="bi bi-arrow-right"></i>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="wrap-rmp mt-5 d-none d-xl-block">
            <div
              class="rm1 chimg"
              data-hsrc="/assets/images/jnglpage/rm-1c.png"
              data-osrc="/assets/images/jnglpage/rm-1.png"
              data-cue="slideInDown"
            >
              <img src="/assets/images/jnglpage/rm-1.png" alt="q3 2023" />
              <div class="rm-wraptexts">
                <h2>Q3 2023</h2>
                <ul>
                  <li>$JNGL Launch</li>
                  <li>Staking Launch</li>
                </ul>
              </div>
            </div>
            <div class="rm2 d-flex">
              <div
                class="rm21 chimg"
                data-hsrc="/assets/images/jnglpage/rm-2.1c.png"
                data-osrc="/assets/images/jnglpage/rm-2.1.png"
                data-cue="slideInLeft"
              >
                <img src="/assets/images/jnglpage/rm-2.1.png" alt="q4 2023" />
                <div class="rm-wraptexts">
                  <h2>Q4 2023</h2>
                  <ul>
                    <li>$JNGL Marketplace</li>
                    <li>Added to 3 CEXs</li>
                  </ul>
                </div>
              </div>
              <div
                class="rm22 chimg"
                data-hsrc="/assets/images/jnglpage/rm-2.2c.png"
                data-osrc="/assets/images/jnglpage/rm-2.2.png"
                data-cue="slideInRight"
              >
                <img src="/assets/images/jnglpage/rm-2.2.png" alt="q1 2024" />
                <div class="rm-wraptexts">
                  <h2>Q1 2024</h2>
                  <ul>
                    <li>Maxis.gg Integration</li>
                    <li>Dev Team Expansion</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="rm3 d-flex">
              <div
                class="rm31 chimg"
                data-hsrc="/assets/images/jnglpage/rm-3.1c.png"
                data-osrc="/assets/images/jnglpage/rm-3.1.png"
                data-cue="slideInLeft"
              >
                <img src="/assets/images/jnglpage/rm-3.1.png" alt="q2 2024" />
                <div class="rm-wraptexts">
                  <h2>Q2 2024</h2>
                  <ul>
                    <li>New Staking Contract</li>
                    <li>Staking Rewards 1.5</li>
                  </ul>
                </div>
              </div>
              <div
                class="rm32 chimg"
                data-hsrc="/assets/images/jnglpage/rm-3.2c.png"
                data-osrc="/assets/images/jnglpage/rm-3.2.png"
                data-cue="slideInRight"
              >
                <img src="/assets/images/jnglpage/rm-3.2.png" alt="q3 2024" />
                <div class="rm-wraptexts">
                  <h2>Q3 2024</h2>
                  <ul>
                    <li>Crafting Mechanism</li>
                    <li>JNGL Marketplace 2.0</li>
                    <li>New Staking Deployment</li>
                    <li>2,500 Holders</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="rm4 chimg"
              data-hsrc="/assets/images/jnglpage/rm-4c.png"
              data-osrc="/assets/images/jnglpage/rm-4.png"
              data-cue="slideInUp"
            >
              <img src="/assets/images/jnglpage/rm-4.png" alt="q4 2024" />
              <div class="rm-wraptexts">
                <h2>Q4 2024</h2>
                <ul>
                  <li>New JNGL Website</li>
                  <li>Token Integrations and allocations</li>
                  <li>Addition to T2 CEX</li>
                  <li>3,000 Holders</li>
                </ul>
              </div>
            </div>
          </div>
          {/*MOBILE*/}
          <div class="rm-m-wrap">
            <div class="rm-m1" data-cue="slideInDown">
              <img src="/assets/images/jnglpage/rm-m1.png" class="img-fluid" />
              <div class="rm-wraptexts">
                <h2>Q3 2023</h2>
                <ul>
                  <li>$JNGL Launch</li>
                  <li>Staking Launch</li>
                </ul>
              </div>
            </div>
            <div class="rm-m21" data-cue="slideInDown">
              <img
                src="/assets/images/jnglpage/rm-m2.1.png"
                class="img-fluid"
              />
              <div class="rm-wraptexts">
                <h2>Q4 2023</h2>
                <ul>
                  <li>$JNGL Marketplace</li>
                  <li>Added to 3 CEXs</li>
                </ul>
              </div>
            </div>
            <div class="rm-m22" data-cue="slideInDown">
              <img
                src="/assets/images/jnglpage/rm-m2.2.png"
                class="img-fluid"
              />
              <div class="rm-wraptexts">
                <h2>Q1 2024</h2>
                <ul>
                  <li>Maxis.gg Integration</li>
                  <li>Dev Team Expansion</li>
                </ul>
              </div>
            </div>
            <div class="rm-m31" data-cue="slideInDown">
              <img
                src="/assets/images/jnglpage/rm-m3.1.png"
                class="img-fluid"
              />
              <div class="rm-wraptexts">
                <h2>Q2 2024</h2>
                <ul>
                  <li>New Staking Contract</li>
                  <li>Staking Rewards 1.5</li>
                </ul>
              </div>
            </div>
            <div class="rm-m32" data-cue="slideInDown">
              <img
                src="/assets/images/jnglpage/rm-m3.2.png"
                class="img-fluid"
              />
              <div class="rm-wraptexts">
                <h2>Q3 2024</h2>
                <ul>
                  <li>Crafting Mechanism</li>
                  <li>JNGL Marketplace 2.0</li>
                  <li>New Staking Deployment</li>
                  <li>2,500 Holders</li>
                </ul>
              </div>
            </div>
            <div class="rm-m4" data-cue="slideInDown">
              <img src="/assets/images/jnglpage/rm-m4.png" class="img-fluid" />
              <div class="rm-wraptexts">
                <h2>Q4 2024</h2>
                <ul>
                  <li>New JNGL Website</li>
                  <li>Token Integrations and allocations</li>
                  <li>Addition to T2 CEX</li>
                  <li>3,000 Holders</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JnglRoadmapCard;
