const JnglFooter = () => {
  return (
    <section id="footer" class="secpad">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="logo" data-cue="slideInLeft">
              <img
                src="/assets/images/jnglpage/f-logo.png"
                alt="Supreme Kong"
              />
            </div>
            <div class="fsocicons">
              <ul data-cues="slideInUp">
                <li>
                  <a href="https://discord.gg/supremekong" target="_blank">
                    <img
                      src="/assets/images/jnglpage/f-discord.png"
                      alt="discord"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://t.co/07J2doTdJp" target="_blank">
                    <img
                      src="/assets/images/jnglpage/f-telegram.png"
                      alt="Telegram"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/Supremekongnft">
                    <img src="/assets/images/jnglpage/f-x.png" alt="Twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://coinmarketcap.com/currencies/jungle-labz/">
                    <img
                      src="/assets/images/jnglpage/f-coinmarketcap.png"
                      alt="CoinMarketCap"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://app.uniswap.org/tokens/ethereum/0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155">
                    <img
                      src="/assets/images/jnglpage/f-uniswap.png"
                      alt="UniSwap"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xcd02a882804f3eb0f2d80f94a04defcea038359f">
                    <img
                      src="/assets/images/jnglpage/f-dextools.png"
                      alt="DexTools"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row flinks mt-5">
          <div class="col-lg-8 col-12">
            <div class="row">
              <div class="col-lg-3 col-12">
                <h4 class="tfc" data-cue="slideInDown">
                  Membership Access
                </h4>
                <ul data-cues="slideInUp">
                  {/*
                  <li>
                    <a
                      href="https://opensea.io/collection/supremekong"
                      target="_blank"
                    >
                      Supreme Kong Genesis
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://opensea.io/collection/supreme-mutant-bananas"
                      target="_blank"
                    >
                      Mutant Supreme Banana
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://opensea.io/collection/supremebananas"
                      target="_blank"
                    >
                      Supreme Banana
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://opensea.io/collection/supreme-kong-2"
                      target="_blank"
                    >
                      Supreme Kong 2
                    </a>
                  </li>
                  */}
                  <li>Coming Soon</li>
                </ul>
              </div>
              <div class="col-lg-3 col-12">
                <h4 class="tfc" data-cue="slideInDown">
                  Resources
                </h4>
                <ul data-cues="slideInUp">
                  {/*
                  <li>
                    <a href="##" target="_blank">
                      Brand Kit
                    </a>
                  </li>
                  <li>
                    <a href="##" target="_blank">
                      Team
                    </a>
                  </li>
                  */}
                  <li>Coming Soon</li>
                </ul>
              </div>
              <div class="col-lg-3 col-12">
                <h4 class="tfc" data-cue="slideInDown">
                  Social
                </h4>
                <ul data-cues="slideInUp">
                  <li>
                    <a href="https://discord.gg/supremekong" target="_blank">
                      Discord
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/jnglcoin" target="_blank">
                      X (Twitter)
                    </a>
                  </li>
                  <li>
                    <a href="https://t.co/07J2doTdJp" target="_blank">
                      Telegram
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-12">
                <h4 class="tfc" data-cue="slideInDown">
                  Staking Lab
                </h4>
                <ul data-cues="slideInUp">
                  {/*
                  <li>
                    <a href="##" target="_blank">
                      Staking Lab
                    </a>
                  </li>
                  */}
                  <li>Coming Soon</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="row">
              <div class="col-12" data-cues="slideInUp">
                <div class="fboxwrap">
                  <img
                    src="/assets/images/jnglpage/fwrench.png"
                    alt=""
                    srcset=""
                  />
                  <p class="fbtit">Developers, Founders, Creators</p>
                  <p class="fbsubt">Let us build together</p>
                </div>
              </div>
              <div class="col-12" data-cues="slideInDown">
                <div class="fboxwrap">
                  <img
                    src="/assets/images/jnglpage/fshake.png"
                    alt=""
                    srcset=""
                  />
                  <p class="fbtit">Supporters, Collectors, Players</p>
                  <p class="fbsubt">Join the Community</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr />
          </div>
          <div class="col-lg-12 text-end">
            <div class="vrybtm">
              <ul data-cue="slideInUp">
                <li>
                  <a href="##">Privacy Policy</a>
                </li>
                <li>
                  <a href="##">Terms of Service</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JnglFooter;
