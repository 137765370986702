const JnglMembershipCard = () => {
  return (
    <section id="feats" class="secpad">
      <div class="stars"></div>
      <div class="stars2"></div>
      <div class="fts-stonetr">
        <img
          src="/assets/images/jnglpage/fts-s-tr.png"
          alt="floating stone"
          srcset=""
        />
      </div>
      <div class="fts-stonebl">
        <img
          src="/assets/images/jnglpage/fts-s-bl.png"
          alt="floating stone"
          srcset=""
        />
      </div>
      <div class="container">
        <div class="row">
          <div class="d-lg-flex align-items-center justify-content-center">
            <div class="col-lg-7 topb" data-cue="slideInLeft">
              <div class="feats-img">
                <img
                  src="/assets/images/jnglpage/fts-ikey.png"
                  class="img-fluid"
                  alt="Membership"
                  srcset=""
                />
              </div>
            </div>
            <div class="col-lg-5 bottomb" data-cue="slideInRight">
              <div class="feats-texts">
                <h2>Membership</h2>
                <h4>
                  Become a part of a historic community and grow from it with
                  leaders.
                </h4>
                <p>
                  Owning $JNGL gives you full access into all the benefits
                  Jungle Labz offers its holdership.
                </p>
                <p> The benefits range from the following perks:</p>
                <ul>
                  <li>Becoming a member of our community</li>
                  <li>Receiving claims and airdrops</li>
                  <li>Access to our IRL events</li>
                  <li>Brand Association</li>
                  <li>Token Gated DeFi Applications</li>
                </ul>
                <p>
                  With just owning 1,000 $JNGL token, you can join Discord. 25K
                  JNGL for token launchpad perks, 50K JNGL for VIP GigaWhale
                  Perks.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="d-lg-flex flex-row-reverse align-items-center justify-content-center">
            <div class="col-lg-7" data-cue="slideInLeft">
              <div class="feats-img">
                <img
                  src="/assets/images/jnglpage/fts-icart.png"
                  class="img-fluid"
                  alt="Spending $JNGL"
                  srcset=""
                />
              </div>
            </div>
            <div class="col-lg-5" data-cue="slideInRight">
              <div class="feats-texts">
                <h2>Spending $JNGL</h2>
                <h4>The ability to spend the token you love.</h4>
                <p>You can spend your $JNGL on multiple things:</p>
                <ul>
                  <li>Upgrading NFT metadata</li>
                  <li>Raffle dapp</li>
                  <li>$JNGL Marketplace</li>
                  <li>Casinos (Coming Soon)</li>
                </ul>
                <p>This expansion of our IP is our leap forward into 3D. </p>
                <p>
                  Our aspirations are to allow game providers to use our 3D
                  models and for us to have $JNGL integrated into multiple
                  platforms, dapps, and games, utilized as a micro transactional
                  token.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="d-lg-flex align-items-center justify-content-center">
            <div class="col-lg-7" data-cue="slideInLeft">
              <div class="feats-img">
                <img
                  src="/assets/images/jnglpage/fts-icog.png"
                  class="img-fluid"
                  alt="NFT MetaData"
                  srcset=""
                />
              </div>
            </div>
            <div class="col-lg-5" data-cue="slideInRight">
              <div class="feats-texts">
                <h2>Upgrading NFT Metadata</h2>
                <h4>
                  A gamified ecosystem with your digital collectibles, powered
                  by our currency $JNGL.
                </h4>
                <p>Leveling System:</p>
                <ul>
                  <li>All 13,800 NFTs in Jungle Labz are upgradeable</li>
                </ul>
                <p>State of The Game:</p>
                <ul>
                  <li>
                    All 4 of Jungle Labz NFT collections can be upgraded using
                    $JNGL, unlocking further rewards.
                  </li>
                </ul>
                <p>The Future:</p>
                <ul>
                  <li>
                    We plan to raise the leveling cap above 10 and introduce new
                    crafting mechanics to gamify our ecosystem further, Coming
                    Q4 2024
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JnglMembershipCard;
