const HeroCard = () => {
  return (
    <section id="hero">
      <video loop muted autoPlay>
        <source
          src="https://d11n7da8rpqbjy.cloudfront.net/aicc/31013299_1724252189422JNJ.mp4"
          type="video/mp4"
        />
      </video>
      <div class="herobg">
        <div class="tljngl cbounce">
          <img src="/assets/images/jnglpage/tl-jngl.png" alt="Jingle" />
        </div>
        <div class="trjngl cbounce2">
          <img src="/assets/images/jnglpage/tr-jngl.png" alt="Jingle" />
        </div>
        <div class="brjngl">
          <img src="/assets/images/jnglpage/br-jngl.png" alt="Jingle" />
        </div>
      </div>
      <div class="container">
        <div class="row g-5 py-5">
          <div class="col-12 ptmain">
            <h1 data-cue="slideInDown">JNGLCOIN</h1>
            <p class="pt-3" data-cue="slideInLeft">
              POWERING JUNGLE LABZ GAMIFIED ECOSYSTEM
            </p>
            <a
              href="https://app.uniswap.org/explore/tokens/ethereum/0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155"
              class="btn btn-primary mt-lg-4"
              data-cue="slideInRight"
            >
              Buy Here
            </a>{" "}
            <a
              href="https://www.supremekong.com/jngl/staking"
              class="btn btn-primary-outline ms-lg-4 mt-lg-4 stakejngl"
              data-cue="slideInRight"
            >
              Stake $JNGL <i class="bi bi-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroCard;
